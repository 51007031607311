import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { EditorService } from 'app/editor/editor.service';

import categories from 'assets/data/categories.json';
import modernModels from 'assets/data/modern-models.json';

interface Categories {
  title?: string;
  img?: string;
}

type Page = 'category' | 'model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  categories: any = [];
  modernModels: any = [];

  models: any = [];

  selectedDoor: any = {};
  page: Page = 'category';

  constructor(
    private router: Router,
    public editorService: EditorService,
  ) { }

  ngOnInit(): void {
    this.categories = categories.categories;
    this.modernModels = modernModels.models;
  }

  selectCategory(category: any): void {
    this.selectedDoor.category = category;

    if (this.selectedDoor.category.id === 'classic') {
      this.selectModel(this.modernModels[0]);

      return;
    }
    if (this.selectedDoor.category.id === 'modern') {
      this.models = this.modernModels;
    }

    this.page = 'model';
  }

  selectModel(model: any): void {
    this.selectedDoor.model = model;
    this.editorService.door = this.selectedDoor;
    this.router.navigate([ '/editor' ]);
  }
}
