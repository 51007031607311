<div class="editor"
  cdkDropListGroup
  *ngIf="isLoaded; else spinner"
>
  <div
    #editor
    class="playground"
  >
    <div class="main-door">
      <app-door
        [assets]="doorAssets"
        [doorName]="doorName"
      ></app-door>

      <img class="tree" src="https://storage.googleapis.com/door-assets/others/tree-new.png" />
      <img class="floor" src="https://storage.googleapis.com/door-assets/others/tile-new.png" />
    </div>

    <div class="logo">
      <img src="https://storage.googleapis.com/door-assets/others/logo.png" />
    </div>
  </div>
  <div class="controles" [ngClass]="{ 'disabled': editorService.isEditing }">
    <app-controles [doorAssets]="doorAssets" [doorName]="doorName"></app-controles>
  </div>
</div>

<ng-template #spinner>
  <div class="spinner">Loading...</div>
</ng-template>
