import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from 'app/dashboard/dashboard.component';
import { ModelsComponent } from 'app/models/models.component';
import { EditorComponent } from 'app/editor/editor.component';

const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'models', component: ModelsComponent },
  { path: 'editor', component: EditorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
