import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { AppComponent } from './app.component';
import { ControlesComponent } from './controles/controles.component';
import { DashboardComponent } from 'app/dashboard/dashboard.component';
import { DoorComponent } from './editor/door/door.component';
import { EditorComponent } from './editor/editor.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { HeaderComponent } from './header/header.component';
import { ModelsComponent } from 'app/models/models.component';
import { ModernModelAComponent } from './editor/modern/modern-model-a/modern-model-a.component';
import { ModernModelBComponent } from './editor/modern/modern-model-b/modern-model-b.component';
import { ModernModelCComponent } from './editor/modern/modern-model-c/modern-model-c.component';
import { ModernModelDComponent } from './editor/modern/modern-model-d/modern-model-d.component';
import { ModernModelEComponent } from './editor/modern/modern-model-e/modern-model-e.component';
import { ModernModelFComponent } from './editor/modern/modern-model-f/modern-model-f.component';
import { SvgDefinitionsComponent } from './svg/svg-definitions/svg-definitions.component';
import { SvgIconComponent } from './svg/svg-icon/svg-icon.component';
import { ClassicModelAComponent } from './editor/classic/classic-model-a/classic-model-a.component';

@NgModule({
  declarations: [
    AppComponent,
    ControlesComponent,
    DashboardComponent,
    DoorComponent,
    EditorComponent,
    FileUploadComponent,
    HeaderComponent,
    ModelsComponent,
    ModernModelAComponent,
    ModernModelBComponent,
    ModernModelCComponent,
    ModernModelDComponent,
    ModernModelEComponent,
    ModernModelFComponent,
    SvgDefinitionsComponent,
    SvgIconComponent,
    ClassicModelAComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    DragDropModule,
    FormsModule,
    HttpClientModule,
    MatProgressSpinnerModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
