<div class="categories">
  <div class="container">
    <ng-container *ngIf="page === 'category'; else modelsTemplate">
      <h4>Categories</h4>

      <div class="categories-cards">
        <div
          class="categories-card"
          [ngClass]="{ 'selected': selectedDoor.category?.name ===  category.name }"
          *ngFor="let category of categories;"
          (click)="selectCategory(category)"
        >
          <img class="category-img" src="{{category.img}}" />
          <span class="category-text">{{category.name}}</span>
        </div>
      </div>
      <!-- <div class="button-container">
        <button (click)="handelSelect('category')" [disabled]="!selectedDoor.category">Next</button>
      </div> -->
    </ng-container>
  </div>
</div>

<ng-template #modelsTemplate let-column="column" ngx-datatable-header-template>
  <h4>Models</h4>
  <div class="models-cards">
    <div
      class="models-card"
      [ngClass]="{ 'selected': selectedDoor.model?.id ===  model.id }"
      *ngFor="let model of models;"
      (click)="selectModel(model)"
    >
      <img class="category-img" src="{{model.img}}" />
      <span class="category-text">{{model.name}}</span>
    </div>
  </div>
  <!-- <div class="button-container">
    <button (click)="handelSelect('model')" [disabled]="!selectedDoor.model?.id">Next</button>
  </div> -->
</ng-template>
