import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    public httpClient: HttpClient,
  ) { }

   get<T>(
    url: string,
    params: Record<string, string | string[]> = {},
    additionalHeaders: Record<string, string | string[]> = {},
  ): Observable<HttpResponse<T>> {
    const headers: Record<string, string | string[]> = {
      ...additionalHeaders,
    };

    return this.httpClient.get<T>(url, {
      headers: new HttpHeaders(headers),
      params: new HttpParams({
        fromObject: params,
      }),
      observe: 'response',
    });
  }

  // post<T>(
  //   url: string,
  //   body: any,
  //   additionalHeaders?: Record<string, string>,
  //   ): Observable<HttpResponse<T>> {

  //   const headerOptions: Record<string, string> = {
  //     ...additionalHeaders,
  //   };

  //   return this._httpClient.post<T>(this.buildApiUrl(url), body, {
  //     headers: new HttpHeaders(headerOptions),
  //     observe: 'response',
  //   });
  // }
}
