<div class="controles-wrapper" [ngClass]="{ 'disabled': editorService.isEditing }">
  <!-- Frames -->
  <div class="controle-row" *ngIf="doorAssets.frames?.length && doorName !== 'classic-model-a'">
    <div class="row-title">Frames</div>
    <div class="preview-items">
      <div
        cdkDropList
        *ngFor="let frame of doorAssets.frames;"
      >
        <div
          cdkDrag
          class="preview-item frame-item"
          [ngStyle]="{'background-image': 'url(' + frame.url + ')'}"
        >
        </div>
        <div class="item-name">{{frame.name}}</div>
      </div>
    </div>
  </div>

  <!-- Lengthwise -->
  <div class="controle-row" *ngIf="doorAssets.lengthwise?.length">
    <div class="row-title">Lengthwise</div>
    <div class="preview-items">
      <div
        cdkDropList
        *ngFor="let board of doorAssets.lengthwise;"
      >
        <div
          cdkDrag
          class="preview-item board-item"
          [ngStyle]="{'background-image': 'url(' + board.url + ')'}"
        >
        </div>
        <div class="item-name">{{ board.name }}</div>
      </div>
    </div>
  </div>

  <!-- Crosswise  -->
  <div class="controle-row" *ngIf="doorAssets.crosswise?.length && !doorName.includes('classic-model-a')">
    <div class="row-title">Crosswise</div>
    <div class="preview-items">
      <div
        cdkDropList
        *ngFor="let board of doorAssets.crosswise;"
      >
        <div
          cdkDrag
          class="preview-item board-item"
          [ngStyle]="{'background-image': 'url(' + board.url + ')'}"
        ></div>
        <div class="item-name">{{ board.name }}</div>
      </div>
    </div>
  </div>

  <!-- 45 Degree  -->
  <div class="controle-row" *ngIf="doorAssets['45-degree']?.length && !doorName.includes('classic-model-a')">
    <div class="row-title">45 Degree</div>
    <div class="preview-items">
      <div
        cdkDropList
        *ngFor="let board of doorAssets['45-degree'];"
      >
        <div
          cdkDrag
          class="preview-item board-item"
          [ngStyle]="{'background-image': 'url(' + board.url + ')'}"
        ></div>
        <div class="item-name">{{ board.name }}</div>
      </div>
    </div>
  </div>

  <!-- Solid Colors  -->
  <div class="controle-row" *ngIf="doorAssets['solid-colors']?.length">
    <div class="row-title">Solid Colors</div>
    <div class="preview-items">
      <div
        cdkDropList
        *ngFor="let board of doorAssets['solid-colors'];"
      >
        <div
          cdkDrag
          class="preview-item board-item"
          [ngStyle]="{'background-image': 'url(' + board.url + ')'}"
        ></div>
        <div class="item-name">{{ board.name }}</div>
      </div>
    </div>
  </div>

  <!-- Bevels  -->
  <div class="controle-row" *ngIf="doorAssets['modern-bevels']?.length && (doorName.includes('modern-model-b') || doorName.includes('classic-model-a'))">
    <!-- Classic Bevels -->
    <ng-container *ngIf="doorName.includes('modern-model-b')">
      <div class="row-title">Bevels</div>
      <div class="preview-items">
        <div
          cdkDropList
          *ngFor="let board of doorAssets['modern-bevels'];"
        >
          <div
            cdkDrag
            class="preview-item bevel-item"
            [ngStyle]="{'background-image': 'url(' + board.url + ')'}"
          ></div>
          <div class="item-name">{{ board.name }}</div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="doorName.includes('classic-model-a')">
      <div class="row-title">Bevels</div>
      <div class="preview-items">
        <div
          cdkDropList
          *ngFor="let board of doorAssets['classic-bevels'];"
        >
          <div
            cdkDrag
            class="preview-item bevel-item"
            [ngStyle]="{'background-image': 'url(' + board.url + ')'}"
          ></div>
          <div class="item-name">{{ board.name }}</div>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Short Handles  -->
  <div class="controle-row" *ngIf="doorAssets['short-handles']?.length">
    <div class="row-title">Short Handles</div>
    <div class="preview-items short-handles">
      <div
        cdkDropList
        *ngFor="let board of doorAssets['short-handles'];"
      >
        <div
          cdkDrag
          class="preview-item handle-item short-handle-item"
          [ngStyle]="{'background-image': 'url(' + board.url + ')'}"
        ></div>
        <div class="item-name">{{ board.name }}</div>
      </div>
    </div>
  </div>

  <!-- Long Handles  -->
  <div class="controle-row" *ngIf="doorAssets['long-handles']?.length">
    <div class="row-title">Long Handles</div>
    <div class="preview-items long-handles">
      <div
        cdkDropList
        *ngFor="let board of doorAssets['long-handles'];"
      >
        <div
          cdkDrag
          class="preview-item handle-item long-handle-item"
          [ngStyle]="{'background-image': 'url(' + board.url + ')'}"
        ></div>
        <div class="item-name">{{ board.name }}</div>
      </div>
    </div>
  </div>

  <!-- Dividers -->
  <div class="controle-row" *ngIf="doorAssets.dividers?.length && !doorName.includes('classic-model-a')">
    <div class="row-title">Dividers</div>
    <div
      class="preview-items dividers"
    >
      <div
        class="preview-item divider-item"
        *ngFor="let divider of doorAssets.dividers;"
        [ngStyle]="{'background-color': divider}"
        (click)="editorService.selectedDividers = divider"
      ></div>
    </div>
  </div>
</div>
