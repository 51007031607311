<div class="door">
  <!-- Shadow -->
  <!-- <div class="shadow"></div> -->

  <div class="edges edge-1"></div>
  <div class="edges edge-2"></div>
  <div class="edges edge-3"></div>

  <!-- Piece 0 -->
  <div
    class="area board piece-0 piece-1 classic-edge"
    [ngStyle]="{'background-image': 'url(' + assets.lengthwise[0]?.url + ')'}"
  ></div>

  <!-- Piece 1 -->
  <div
    class="area board classic piece-1"
    cdkDropList
    (cdkDropListDropped)="onDroped($event)"
    (cdkDropListEntered)="onEntered($event)"
    (cdkDropListExited)="onExit($event)"
    [ngStyle]="{'background-image': 'url(' + assets.lengthwise[0]?.url + ')'}"
  ></div>

  <!-- Bevels 1 -->
    <div
    class="area bevel"
    cdkDropList
    (cdkDropListDropped)="onDroped($event)"
    (cdkDropListEntered)="onEntered($event)"
    (cdkDropListExited)="onExit($event)"
    [ngStyle]="{'background-image': 'url(' + assets['classic-bevels'][0]?.url + ')'}"
  ></div>

  <!-- Short Handle -->
  <div
    #shortHandle
    class="area handle short-handle"
    cdkDropList
    (cdkDropListDropped)="onDroped($event)"
    (cdkDropListEntered)="onEntered($event)"
    (cdkDropListExited)="onExit($event)"
    [ngStyle]="{'background-image': 'url(' + assets['short-handles'][0]?.url + ')'}"
    [ngClass]="{
      'editing': editorService.isEditing
    }"
  >
  </div>

  <!-- Divider 1 -->
  <!-- <div
    class="divider divider-1"
    [ngStyle]="{'background-color': editorService.selectedDividers || assets.dividers[0]}"
    [ngClass]="{ 'editing': editorService.isEditing }"
  ></div> -->
</div>
