<div [ngSwitch]="doorName">
  <!-- Modern -->
  <app-modern-model-a *ngSwitchCase="'modern-model-a'" [assets]="assets"></app-modern-model-a>
  <app-modern-model-b *ngSwitchCase="'modern-model-b'" [assets]="assets"></app-modern-model-b>
  <app-modern-model-c *ngSwitchCase="'modern-model-c'" [assets]="assets"></app-modern-model-c>
  <app-modern-model-d *ngSwitchCase="'modern-model-d'" [assets]="assets"></app-modern-model-d>
  <app-modern-model-e *ngSwitchCase="'modern-model-e'" [assets]="assets"></app-modern-model-e>
  <app-modern-model-f *ngSwitchCase="'modern-model-f'" [assets]="assets"></app-modern-model-f>
  <!-- Classic -->
  <app-classic-model-a *ngSwitchCase="'classic-model-a'" [assets]="assets"></app-classic-model-a>
</div>
