<div class="door">
  <!-- Frames -->
  <div
    class="area frame"
    cdkDropList
    (cdkDropListDropped)="onDroped($event)"
    (cdkDropListEntered)="onEntered($event)"
    (cdkDropListExited)="onExit($event)"
    [ngStyle]="{'background-image': 'url(' + assets.frames[1]?.url + ')'}"
  ></div>

  <!-- Shadow -->
  <div class="shadow"></div>

  <!-- Piece 1 -->
  <div
    class="area board piece-1"
    cdkDropList
    (cdkDropListDropped)="onDroped($event)"
    (cdkDropListEntered)="onEntered($event)"
    (cdkDropListExited)="onExit($event)"
    [ngStyle]="{'background-image': 'url(' + assets['solid-colors'][0]?.url + ')'}"
  ></div>

  <!-- Piece 2 -->
  <div
    class="area board piece-2"
    cdkDropList
    (cdkDropListDropped)="onDroped($event)"
    (cdkDropListEntered)="onEntered($event)"
    (cdkDropListExited)="onExit($event)"
    [ngStyle]="{'background-image': 'url(' + assets.lengthwise[1]?.url + ')'}"
  ></div>

  <!-- Bevels 1 -->
    <div
    class="area bevel"
    cdkDropList
    (cdkDropListDropped)="onDroped($event)"
    (cdkDropListEntered)="onEntered($event)"
    (cdkDropListExited)="onExit($event)"
    [ngStyle]="{'background-image': 'url(' + assets['modern-bevels'][1]?.url + ')'}"
  ></div>

  <!-- Short Handle -->
  <div
    #shortHandle
    class="area handle short-handle"
    cdkDropList
    (cdkDropListDropped)="onDroped($event)"
    (cdkDropListEntered)="onEntered($event)"
    (cdkDropListExited)="onExit($event)"
    [ngStyle]="{'background-image': 'url(' + assets['short-handles'][0]?.url + ')'}"
    [ngClass]="{
      'editing': editorService.isEditing
    }"
  >
  </div>

  <!-- Divider 1 -->
  <div
    class="divider divider-1"
    [ngStyle]="{'background-color': editorService.selectedDividers || assets.dividers[3]}"
    [ngClass]="{ 'editing': editorService.isEditing }"
  ></div>
</div>
