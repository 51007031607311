import { Component, Input } from '@angular/core';

import { EditorService } from 'app/editor/editor.service';

import { DoorAssets } from 'app/types/door.types';

@Component({
  selector: 'app-door',
  templateUrl: './door.component.html'
})
export class DoorComponent {

  @Input() assets: DoorAssets;
  @Input() doorName: string;

  constructor(
    public editorService: EditorService,
  ) { }

  onDroped(event: any): void {
    event.container.element.nativeElement.classList.remove('drag-over');

    const dragedElement: HTMLElement = event.item.element.nativeElement;
    const dropedElement: HTMLElement = event.container.element.nativeElement;

    if (dragedElement.className.includes('frame-item') && dropedElement.className.includes('frame')) {
      dropedElement.style.backgroundImage = dragedElement.style.backgroundImage;
    }
    if (dragedElement.className.includes('board-item') && dropedElement.className.includes('board')) {
      dropedElement.style.backgroundImage = dragedElement.style.backgroundImage;

      if (dropedElement.className.includes('classic')) {
        const item: any = document.querySelector('.classic-edge');
        item.style.backgroundImage = dragedElement.style.backgroundImage;
      }
    }

    if (dragedElement.className.includes('bevel-item') && dropedElement.className.includes('bevel')) {
      dropedElement.style.backgroundImage = dragedElement.style.backgroundImage;
    }

    if (dragedElement.className.includes('handle-item') && dropedElement.className.includes('handle')) {
      dropedElement.style.backgroundImage = dragedElement.style.backgroundImage;

      if (dragedElement.className.includes('short-handle-item')) {
        dropedElement.classList.add('short-handle');
        dropedElement.classList.remove('long-handle');
      }

      if (dragedElement.className.includes('long-handle-item')) {
        dropedElement.classList.add('long-handle');
        dropedElement.classList.remove('short-handle');
      }
    }

    document.querySelector('.bevel')?.classList.remove('hidden');
  }

  onEntered(event: any): void {
    event.container.element.nativeElement.innerHTML = '';
    const dragedElement: HTMLElement = event.item.element.nativeElement;
    const dropedElement: HTMLElement = event.container.element.nativeElement;

    if (dragedElement.className.includes('frame-item') && dropedElement.className.includes('frame')) {
      event.container.element.nativeElement.classList.add('drag-over');
    }

    if (dragedElement.className.includes('board-item')) {
      if (dropedElement.className.includes('bevel')) {
        document.querySelector('.bevel')?.classList.add('hidden');
      }

      if (dropedElement.className.includes('board')) {
        event.container.element.nativeElement.classList.add('drag-over');
      }
    }

    if (dragedElement.className.includes('bevel-item') && dropedElement.className.includes('bevel')) {
      event.container.element.nativeElement.classList.add('drag-over');
    }

    if (dragedElement.className.includes('handle-item') && dropedElement.className.includes('handle')) {
      event.container.element.nativeElement.classList.add('drag-over');
    }
  }

  onExit(event: any): void {
    event.container.element.nativeElement.classList.remove('drag-over');
  }
}
