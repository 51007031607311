<div class="nav">
  <ul>
    <li><a href=""><app-svg-icon class="nav-icon" name="svg-door"></app-svg-icon></a></li>
    <li
      *ngIf="editorService.showControlles"
    >
      <button>
        <a
          href="https://console.cloud.google.com/storage/browser/door-assets?project=southern-lane-331015&pageState=(%22StorageObjectListTable%22:(%22f%22:%22%255B%255D%22))&prefix=&forceOnObjectsSortingFiltering=false"
          target="_blank"
        >
          <app-svg-icon class="nav-icon" name="svg-edit" [ngClass]="{ 'active': editorService.isEditing }"></app-svg-icon>
        </a>
      </button>
    </li>
    <li
      *ngIf="editorService.showControlles"
    >
      <button (click)="toggleDiaglog()">
        <app-svg-icon class="nav-icon" name="svg-download" [ngClass]="{ 'active': editorService.isEditing }"></app-svg-icon>
      </button>
    </li>
    <li
      *ngIf="editorService.showControlles"
    >
      <button (click)="editorService.exitFullscreen()" *ngIf="editorService.isFullScreen">
        <app-svg-icon class="nav-icon" name="close-fullscreen" [ngClass]="{ 'active': editorService.isEditing }"></app-svg-icon>
      </button>
      <button (click)="editorService.toggleFullscreen$.next(true)" *ngIf="!editorService.isFullScreen">
        <app-svg-icon class="nav-icon" name="compress" [ngClass]="{ 'active': editorService.isEditing }"></app-svg-icon>
      </button>
    </li>
  </ul>
</div>

<div class="dialog" *ngIf="isOpened">
  <div class="dialog-content">
    <div>SAVE IMAGE:</div>
    <input class="file-name" type="text" [(ngModel)]="fileName"/>
    <div class="button-container">
      <button class="yellow" [disabled]="!fileName" (click)="saveImage()">Save</button>
      <button (click)="toggleDiaglog()">Cancel</button>
    </div>
  </div>
</div>
