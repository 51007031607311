import { Component, Input } from '@angular/core';

import { EditorService } from 'app/editor/editor.service';

import { DoorAssets } from 'app/types/door.types';
@Component({
  selector: 'app-controles',
  templateUrl: './controles.component.html',
  styleUrls: ['./controles.component.scss']
})
export class ControlesComponent {

  @Input() doorAssets: DoorAssets;
  @Input() doorName: string;

  constructor(
    public editorService: EditorService,
  ) { }
}
