import { Component, OnInit } from '@angular/core';

import { EditorService } from 'app/editor/editor.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isOpened: boolean = false;
  fileName: string = '';

  constructor(
    public editorService: EditorService,
  ) { }

  ngOnInit(): void {
  }

  toggleDiaglog(): void {
    this.fileName = '';
    // this.isOpened = !this.isOpened;
    // this.editorService.toggleFullscreen$.next(false);
    this.editorService.saveImage(new Date().toString());
  }

  saveImage(): void {
    this.editorService.saveImage(this.fileName);
    this.toggleDiaglog();
  }
}
