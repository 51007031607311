import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import Html2canvas from 'html2canvas';

import { ApiService } from 'app/util/api/api.service';
import { EditorService } from './editor.service';

import { DoorAssets } from 'app/types/door.types';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit, OnDestroy {

  path: string = 'https://storage.googleapis.com/door-assets/';
  fileName: string = '';
  doorName: string;
  isLoaded: boolean = false;

  doorAssets: DoorAssets = {
    dividers: ['#ab8949', '#e0e7d5', '#000000'],
    frames: [],
    handle: [],
    crosswise: [],
    lengthwise: [],
    'modern-bevels': [],
    '45-degree': [],
    'short-handles': [],
    'long-handles': [],
    'solid-colors': [],
    'classic-bevels': [],
  };

  constructor(
    public apiService: ApiService,
    public editorService: EditorService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    if (!this.editorService.door) {
      this.router.navigate([ '/' ]);

      return;
    }

    // Handle full screen
    this.requestFullScreen();
    this.editorService.toggleFullscreen$.subscribe((next) => {
      if (next) {
        this.requestFullScreen();
      } else {
        this.editorService.isFullScreen = false;
        document.exitFullscreen();
      }
    });
    this.editorService.saveTrigger$.subscribe(this.saveImage);

    this.editorService.showControlles = true;

    this.doorName = `${this.editorService.door.category?.id}-${this.editorService.door.model?.id}`;

    this.apiService.get('https://storage.googleapis.com/storage/v1/b/door-assets/o/').subscribe((data: any) => {
      if (!!data.body.items) {
        const items = data.body.items;
        for (let i: number = 0; i < items.length; i++) {
          if (['image/png', 'image/jpeg'].includes(items[i].contentType)) {
            const firstIndex = items[i].name.indexOf('/') + 1;
            const lastIndex = items[i].name.lastIndexOf('.');

            if (items[i].name.includes('frames/')) {
              this.doorAssets['frames']?.push({
                name: items[i].name.substring(firstIndex, lastIndex),
                url: this.path + items[i].name
              });
            } else if (items[i].name.includes('crosswise/')) {
              this.doorAssets.crosswise?.push({
                name: items[i].name.substring(firstIndex, lastIndex),
                url: this.path + items[i].name
              });
            } else if (items[i].name.includes('lengthwise/')) {
              this.doorAssets.lengthwise?.push({
                name: items[i].name.substring(firstIndex, lastIndex),
                url: this.path + items[i].name
              });
            } else if (items[i].name.includes('45-degree/')) {
              this.doorAssets['45-degree']?.push({
                name: items[i].name.substring(firstIndex, lastIndex),
                url: this.path + items[i].name
              });
            } else if (items[i].name.includes('solid-colors/')) {
              this.doorAssets['solid-colors']?.push({
                name: items[i].name.substring(firstIndex, lastIndex),
                url: this.path + items[i].name
              });
            } else if (items[i].name.includes('short-handles/')) {
              this.doorAssets['short-handles']?.push({
                name: items[i].name.substring(firstIndex, lastIndex),
                url: this.path + items[i].name
              });
            } else if (items[i].name.includes('long-handles/')) {
              this.doorAssets['long-handles']?.push({
                name: items[i].name.substring(firstIndex, lastIndex),
                url: this.path + items[i].name
              });
            } else if (items[i].name.includes('modern-bevels/')) {
              this.doorAssets['modern-bevels']?.push({
                name: items[i].name.substring(firstIndex, lastIndex),
                url: this.path + items[i].name
              });
            } else if (items[i].name.includes('classic-bevels/')) {
              this.doorAssets['classic-bevels'].push({
                name: items[i].name.substring(firstIndex, lastIndex),
                url: this.path + items[i].name
              });
            }
          }
        }
      }

      this.isLoaded = true;
    });
  }

  handelStyle(key: string, value: string): void {
    this.editorService.style[key] = value;
  }

  saveImage(filename: string): void {
    this.fileName = filename;

    Html2canvas(document.querySelector('.playground') as HTMLElement, {
      logging: true,
      allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      const myImage: string = canvas.toDataURL('image/jpeg');
      const link = document.createElement('a');
      link.download = this.fileName;
      link.href = myImage;
      link.click();
    });
  }

  requestFullScreen(): void {
    this.editorService.isFullScreen = true;
    const element: any = document.body;
    const requestMethod =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      element.msRequestFullScreen;

    if (requestMethod) { // Native full screen.
        requestMethod.call(element);
    }
  }

  ngOnDestroy(): void {
    this.editorService.showControlles = false;
  }
}
