import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditorService {
  door: any = null;
  style: any = {};
  isEditing = false;
  showControlles = false;
  draggedArea: HTMLElement | any;
  selectedDividers: string;
  selectedStyle: any = {};
  isFullScreen: boolean = false;

  saveTrigger$: Subject<string> = new Subject();
  toggleFullscreen$: Subject<boolean> = new Subject();

  constructor() { }

  toggelEditing(): void {
    this.isEditing = !this.isEditing;
  }

  saveImage(filename: string): void {
    this.saveTrigger$.next(filename);
  }

  exitFullscreen(): void {
    this.toggleFullscreen$.next(false);
  }
}
