<div class="door">
  <!-- Frames -->
  <div
    class="area frame"
    cdkDropList
    (cdkDropListDropped)="onDroped($event)"
    (cdkDropListEntered)="onEntered($event)"
    (cdkDropListExited)="onExit($event)"
    [ngStyle]="{'background-image': 'url(' + assets.frames[6]?.url + ')'}"
  ></div>

  <!-- Shadow -->
  <div class="shadow"></div>

  <!-- Piece 1 -->
  <div
    class="area board piece-1"
    cdkDropList
    (cdkDropListDropped)="onDroped($event)"
    (cdkDropListEntered)="onEntered($event)"
    (cdkDropListExited)="onExit($event)"
    [ngStyle]="{'background-image': 'url(' + assets.crosswise[6]?.url + ')'}"
  ></div>

  <!-- Piece 2 -->
  <div
    class="area board piece-2"
    cdkDropList
    (cdkDropListDropped)="onDroped($event)"
    (cdkDropListEntered)="onEntered($event)"
    (cdkDropListExited)="onExit($event)"
    [ngStyle]="{'background-image': 'url(' + assets.crosswise[6]?.url + ')'}"
  ></div>

  <!-- Piece 3 -->
  <div
    class="area board piece-3"
    cdkDropList
    (cdkDropListDropped)="onDroped($event)"
    (cdkDropListEntered)="onEntered($event)"
    (cdkDropListExited)="onExit($event)"
    [ngStyle]="{'background-image': 'url(' + assets.crosswise[6]?.url + ')'}"
  ></div>

  <!-- Piece 4 -->
  <div
    class="area board piece-4"
    cdkDropList
    (cdkDropListDropped)="onDroped($event)"
    (cdkDropListEntered)="onEntered($event)"
    (cdkDropListExited)="onExit($event)"
    [ngStyle]="{'background-image': 'url(' + assets['solid-colors'][2]?.url + ')'}"
  ></div>

  <!-- Piece 4 -->
  <div
    class="area board piece-5"
    cdkDropList
    (cdkDropListDropped)="onDroped($event)"
    (cdkDropListEntered)="onEntered($event)"
    (cdkDropListExited)="onExit($event)"
    [ngStyle]="{'background-image': 'url(' + assets['solid-colors'][2]?.url + ')'}"
  ></div>

  <!-- Short Handle -->
  <div
    #shortHandle
    class="area handle short-handle"
    cdkDropList
    (cdkDropListDropped)="onDroped($event)"
    (cdkDropListEntered)="onEntered($event)"
    (cdkDropListExited)="onExit($event)"
    [ngStyle]="{'background-image': 'url(' + assets['short-handles'][1]?.url + ')'}"
    [ngClass]="{
      'editing': editorService.isEditing
    }"
    >
  </div>

  <!-- Divider 1 / 1 -->
  <div
    class="divider divider-1"
    [ngStyle]="{'background-color': editorService.selectedDividers || assets.dividers[1]}"
    [ngClass]="{ 'editing': editorService.isEditing }"
  ></div>

  <!-- Divider 1 / 2 -->
  <div
    class="divider divider-2"
    [ngStyle]="{'background-color': editorService.selectedDividers || assets.dividers[1]}"
    [ngClass]="{ 'editing': editorService.isEditing }"
  ></div>
  <!-- Divider 1 / 3 -->
  <div
    class="divider divider-3"
    [ngStyle]="{'background-color': editorService.selectedDividers || assets.dividers[1]}"
    [ngClass]="{ 'editing': editorService.isEditing }"
  ></div>
  <!-- Divider 1 / 4 -->
  <div
    class="divider divider-4"
    [ngStyle]="{'background-color': editorService.selectedDividers || assets.dividers[1]}"
    [ngClass]="{ 'editing': editorService.isEditing }"
  ></div>
</div>
