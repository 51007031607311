import { Component, Input } from '@angular/core';

import { DoorComponent } from 'app/editor/door/door.component';

import { EditorService } from 'app/editor/editor.service';

import { DoorAssets } from 'app/types/door.types';

@Component({
  selector: 'app-modern-model-a',
  templateUrl: './modern-model-a.component.html',
  styleUrls: ['./modern-model-a.component.scss']
})
export class ModernModelAComponent extends DoorComponent {

  @Input() assets: DoorAssets;

  constructor(
    public editorService: EditorService,
  ) {
    super(editorService);
  }
}
